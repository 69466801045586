/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-24",
    versionChannel: "nightly",
    buildDate: "2023-11-24T00:15:06.984Z",
    commitHash: "96059a8e25603ba9dddaf7d178191e9304a11045",
};
